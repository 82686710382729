import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@seddi/ui-kit';

import { ThemeApp } from './theme';
import { useSelector } from 'react-redux';
import { SeddiLoginState } from '../store/store';

export const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { name } = useSelector((state: SeddiLoginState) => state.app);
  const [theme, setTheme] = useState(ThemeApp);
  useEffect(() => {
    const { sa1, sa2, st1, st2, sf1, sf2, ac21, ac22 } = ThemeApp.color;
    const brandedColors = { ...ThemeApp.color };
    switch (name) {
      case 'fitroom':
        brandedColors.s1 = sf1;
        brandedColors.s2 = sf2;
        break;
      case 'textura':
        brandedColors.s1 = st1;
        brandedColors.s2 = st2;
        break;
      case 'decorator':
        brandedColors.s1 = ac21;
        brandedColors.s2 = ac22;
        break;
      case 'author':
      default:
        brandedColors.s1 = sa1;
        brandedColors.s2 = sa2;
        break;
    }
    setTheme({ ...ThemeApp, color: brandedColors });
  }, [name]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
